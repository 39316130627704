/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { usdFormatter } from "../../tools";
import PayrollEditor from './PayrollEditor/PayrollEditor';
import PayrollPeriod from "./PayrollPeriod/PayrollPeriod";
import PageSpinner from "../../components/PageSpinner";
import { getPayrollHistory } from "../../services/PayrollServiceMtb";
import QuickTable from "../../components/QuickTable";
import Button from 'react-bootstrap/Button';
import CreateCustomPayroll from "./CreateCustomPayroll";

export default class PayrollAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            companies: [],
            periods: [],
            selectedPayPeriod: undefined,
            urlParamsInitialized: false,
            modalSwitch: 'none',
        }

        this.loadData = this.loadData.bind(this);
        this.handleAddPayrollPeriod = this.handleAddPayrollPeriod.bind(this);
        this.handleDeletingCustomPeriod = this.handleDeletingCustomPeriod.bind(this);
    }

    sortPayrollPeriods(a, b) {
        if (moment(a.periodStart).isBefore(moment(b.periodStart), 'days')) {
            return 1;
        } else if (moment(b.periodStart).isBefore(moment(a.periodStart), 'days')) {
            return -1;
        } else {
            if (a.isCustom == '1') {
                return -1;
            } else if (b.isCustom == '1') {
                return 1;
            } else {
                return 0;
            }
        }
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        getPayrollHistory().then((response) => {
            if (response.status === 200) {
                this.setState({periods: response.payrollPeriods, companies: response.companies});
                if (!this.state.urlParamsInitialized) {
                    const searchParams = new URLSearchParams(window.location.search);
                    const payrollIdentifier = searchParams.get('uid');
                    const payrollPeriod = response.payrollPeriods.find(p => p.uid == payrollIdentifier);
                    this.setState({ selectedPayPeriod: payrollPeriod, modalSwitch: !!payrollPeriod?.ttaSubmission ? 'viewer' : !!payrollPeriod ? 'editor' : '', urlParamsInitialized: true });
                }
            }
            
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    

    handleAddPayrollPeriod(period) {
        const newArray = Array.from(this.state.periods);
        newArray.push(period);
        this.setState({periods: newArray, modalSwitch: ''});
    }

    handleDeletingCustomPeriod(uid){
        const newArr = Array.from(this.state.periods).filter((p)=>p.uid !== uid);
        this.setState({periods:newArr});
    }

    render() {

        const ICRows = this.state.periods.filter(r => !r.clientSubmission && !r.ttaSubmission).map((period) => {

            const company = this.state.companies.find(c => c.uid == period.companyIdentifier);


            return (
                <tr key={period.uid} className="cursor-pointer" onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}>
                    <td>{period.uid}</td>
                    <td>{company?.name}</td>
                    <td>{period.location?.name ?? 'All Locations'}</td>
                    <td>{moment(period.periodStart).format('MMM D, YYYY') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
                    <td>{period.isCustom == 1 ? 'Yes' : ''}</td>
                </tr>
            )
        });
        
        const TTARows = this.state.periods.filter(r => r.clientSubmission && !r.ttaSubmission).map((period) => {

            const company = this.state.companies.find(c => c.uid == period.companyIdentifier);

            return (
                <tr key={period.uid} className="cursor-pointer" onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}>
                    <td>{period.uid}</td>
                    <td>{company?.name}</td>
                    <td>{period.location?.name ?? 'All Locations'}</td>
                    <td>{moment(period.periodStart).format('MMM D, YYYY') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
                    <td>{usdFormatter.format(period.gross)}</td>
                    <td>{period.isCustom == 1 ? 'Yes' : ''}</td>
                </tr>
            )
        });

        const historyRows = this.state.periods.filter(r => r.ttaSubmission).map((period) => {

            const company = this.state.companies.find(c => c.uid == period.companyIdentifier);
           
            return (
                <tr key={period.uid} className='cursor-pointer' onClick={() => {this.setState({modalSwitch: 'viewer', selectedPayPeriod: period})}}>
                    <td>{period.uid}</td>
                    <td>{company?.name}</td>
                    <td>{period.location?.name ?? 'All Locations'}</td>
                    <td>{moment(period.periodStart).format('MMM D, YYYY') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
                    <td>{usdFormatter.format(period.gross)}</td>
                    <td>{period.isCustom == 1 ? 'Yes' : ''}</td>
                </tr>
            )
        });

        const content = this.state.isLoading ? '' : (
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                    <Button onClick={() => {this.setState({modalSwitch: 'createCustom'})}}>Create Custom Payroll</Button>
                </div>
                <Card>
                    <Card.Body>
                        <Card.Title>Ready for Company Review</Card.Title>
                        <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                            <QuickTable size='sm' headers={['UID', 'Company', 'Location', 'Period', 'Custom?']} widths={[null, null, null, null, 1]} rows={ICRows} hover/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Ready for TTA Review</Card.Title>
                        <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                            <QuickTable size='sm' headers={['UID', 'Company', 'Location', 'Period', 'Gross', 'Custom?']} widths={[null, null, null, null, null, 1]} rows={TTARows} hover/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>History</Card.Title>
                        <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                            <QuickTable headers={['UID', 'Company', 'Location', 'Period', 'Gross', 'Custom?']} widths={[null, null, null, null, null, 1]} rows={historyRows} hover/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    
        return (
            <div className="page-wrapper" style={{padding: 12}}>
                {this.state.isLoading ? <PageSpinner/> : content}
                <Modal show={this.state.modalSwitch === 'editor'} backdrop='static' fullscreen>
                    <PayrollEditor 
                        selectedPayPeriod={this.state.selectedPayPeriod} 
                        hideModal={() => {this.setState({modalSwitch: 'none'})}} 
                        loadData={this.loadData}
                        companyName={this.state.selectedPayPeriod ? this.state.companies.find(c => c.uid == this.state.selectedPayPeriod.companyIdentifier).name : ''} 
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'viewer'} size='xl' centered onHide={() => {this.setState({modalSwitch: 'none'})}} fullscreen>
                    <PayrollPeriod 
                        selectedPayPeriod={this.state.selectedPayPeriod} 
                        companyName={this.state.selectedPayPeriod ? this.state.companies.find(c => c.uid === this.state.selectedPayPeriod.companyIdentifier).name : ''} 
                        editPeriod={() => {this.setState({modalSwitch: 'editor'})}}
                    /> 
                </Modal>
                <Modal show={this.state.modalSwitch === 'createCustom'} onHide={() => {this.setState({modalSwitch: 'none'})}} centered>
                    <CreateCustomPayroll companies={this.state.companies} handleAddPayrollPeriod={this.handleAddPayrollPeriod} payrollPeriods={this.state.periods.filter(p => p.isCustom == 0)}/>
                </Modal>
            </div>
        )
    }
}