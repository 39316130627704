/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import { Card, Container, OverlayTrigger, Popover } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { usdFormatter } from '../../tools';
import PayrollEditor from './PayrollEditor/PayrollEditor';
import PayrollPeriod from './PayrollPeriod/PayrollPeriod';
import PageSpinner from '../../components/PageSpinner';
import { getActivePayroll, getHistoricPayroll, advancePendingPayrollPeriod } from '../../services/PayrollServiceAdmin';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QuickTable from '../../components/QuickTable';
import Button from 'react-bootstrap/Button';
import AlertModal from '../../components/AlertModals/AlertModal';
import CreateCustomPayroll from './CreateCustomPayroll';
import Cookies from 'universal-cookie';
import GeneratePayrollModal from './GeneratePayrollModal';
import GeneratePTOAccrualModal from './GeneratePTOAccrualModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft, faChevronRight, faX } from '@fortawesome/free-solid-svg-icons';
import LoadingWrapper from '../../components/LoadingWrapper';

export default class PayrollAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingHistoric: false,
      page: 0,
      count: 10,
      companies: [],
      periods: [],
      historicPeriods: [],
      pageIndex: 0,
      selectedPayrollIdentifier: undefined,
      selectedPayPeriod: undefined,
      urlParamsInitialized: false,
      modalSwitch: 'none',
    };

    this.loadData = this.loadData.bind(this);
    this.handleAdvancePendingPayrollPeriod = this.handleAdvancePendingPayrollPeriod.bind(this);
    this.handleAddPayrollPeriod = this.handleAddPayrollPeriod.bind(this);
    this.handleDeletingCustomPeriod = this.handleDeletingCustomPeriod.bind(this);
  }

  sortPayrollPeriods(a, b) {
    if (moment(a.periodStart).isBefore(moment(b.periodStart), 'days')) {
      return 1;
    } else if (moment(b.periodStart).isBefore(moment(a.periodStart), 'days')) {
      return -1;
    } else {
      if (a.isCustom == '1') {
        return -1;
      } else if (b.isCustom == '1') {
        return 1;
      } else {
        return 0;
      }
    }
  }

  async loadData() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }
    const response = await getActivePayroll();
    if (response && response.status === '200') {
      this.setState({ periods: response.payrollPeriods.sort(this.sortPayrollPeriods), companies: response.companies });
    }
    this.loadHistoricPayroll();
    this.setState({ isLoading: false });
  }

  async loadHistoricPayroll() {
    this.setState({ isLoadingHistoric: true });
    const response = await getHistoricPayroll(this.state.page, this.state.count);
    if (response && response.status === '200') {
      this.setState({ historicPeriods: response.payrollPeriods.sort(this.sortPayrollPeriods) });
    }
    this.setState({ isLoadingHistoric: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page != prevState.page) {
      this.loadHistoricPayroll();
    }
    if (!this.state.urlParamsInitialized && !!this.state.selectedPayrollIdentifier) {
      if (
        this.state.periods != prevState.periods ||
        this.state.historicPeriods != prevState.historicPeriods ||
        this.state.selectedPayrollIdentifier != prevState.selectedPayrollIdentifier
      ) {
        const payrollPeriod = [...this.state.historicPeriods, ...this.state.periods].find(
          (p) => p.uid == this.state.selectedPayrollIdentifier
        );
        this.setState({
          selectedPayPeriod: payrollPeriod,
          modalSwitch: !!payrollPeriod?.approvedEntries ? 'viewer' : !!payrollPeriod ? 'editor' : '',
          urlParamsInitialized: !!payrollPeriod,
        });
      }
    }
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const payrollIdentifier = searchParams.get('uid');
    this.setState({ selectedPayrollIdentifier: payrollIdentifier });
    this.loadData();
  }

  async handleAdvancePendingPayrollPeriod() {
    try {
      const response = await advancePendingPayrollPeriod(this.state.selectedPayPeriod.uid);
      if (response && response.status === '200') {
        const newArray = Array.from(this.state.periods);
        newArray.find((e) => e.uid === this.state.selectedPayPeriod.uid).status = '1';
        this.setState({ periods: newArray });
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  handleAddPayrollPeriod(period) {
    const newArray = Array.from(this.state.periods);
    newArray.push(period);
    this.setState({ periods: newArray, modalSwitch: '' });
  }

  handleDeletingCustomPeriod(uid) {
    const newArr = Array.from(this.state.periods).filter((p) => p.uid !== uid);
    this.setState({ periods: newArr });
  }

  payrollStatusUncompletedKey = ['Pending', 'Ready for Review', 'Sent to Payroll', 'Approved by TTA'];
  payrollStatusCompletedKey = ['Pending', 'Reviewed', 'Approved by Payroll', 'Approved by TTA'];
  payrollStatusKey = ['Pending', 'Ready for Review', 'Sent to Payroll', 'Approved by TTA', 'Approved by TTA'];

  render() {
    // const pendingRows = this.state.periods.filter(r => r.status === '0').map((period) => {
    //     return (
    //         <tr key={period.uid} className="cursor-pointer" onClick={() => {this.setState({modalSwitch: 'advancePendingPeriod', selectedPayPeriod: period})}}>
    //             <td>{period.uid}</td>
    //             <td>{this.state.companies.find(r => r.uid === period.companyIdentifier).companyName}</td>
    //             <td>{moment(period.periodStart).format('MMM D, YYYY') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
    //         </tr>
    //     )
    // });

    const ICRows = this.state.periods
      .filter((r) => r.status === '1')
      .map((period) => {
        const bcPayrollEnabled =
          this.state.companies.find((c) => c.uid == period.companyIdentifier)?.bcPayrollEnabled == '1';
        const restrictBcsToCsa =
          this.state.companies.find((c) => c.uid == period.companyIdentifier)?.restrictBcsToCsa == '1';
        const csaElements = period.completedCsas.map((csaName) => {
          return <li key={csaName}>{csaName}</li>;
        });

        return (
          <tr
            key={period.uid}
            className="cursor-pointer"
            onClick={() => {
              this.setState({ modalSwitch: 'editor', selectedPayPeriod: period });
            }}
          >
            <td>{period.uid}</td>
            <td>{this.state.companies.find((r) => r.uid === period.companyIdentifier).companyName}</td>
            <td>{period.csaName ?? 'All'}</td>
            <td>
              {moment(period.periodStart).format('MMM D, YYYY') +
                ' - ' +
                moment(period.periodEnd).format('MMM D, YYYY')}
            </td>
            <td>
              {!bcPayrollEnabled ? ( //expects no BC submissions
                'N/A'
              ) : restrictBcsToCsa && !period.csaIdentifier ? ( //expects multiple BC submissions
                <OverlayTrigger
                  overlay={
                    <Popover style={{ position: 'fixed' }}>
                      <Popover.Body>
                        <ul style={{ marginBottom: 0 }}>{csaElements}</ul>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span
                    className="cursor-pointer"
                    style={{
                      border: '1px solid lightgray',
                      color: period.completedCsas.length == period.numberOfCsas ? 'limegreen' : 'rgb(180, 180, 0)',
                      borderColor:
                        period.completedCsas.length == period.numberOfCsas ? 'limegreen' : 'rgb(180, 180, 0)',
                      borderRadius: 6,
                      padding: 2,
                    }}
                  >{`${period.completedCsas.length} / ${period.numberOfCsas} CSAs`}</span>
                </OverlayTrigger>
              ) : period.completedCsas.length > 0 ? ( //expects 1 submission & 1 submission is present
                <FontAwesomeIcon icon={faCheck} style={{ color: 'limegreen' }} />
              ) : (
                //expects 1 submission & no submissions are present
                <FontAwesomeIcon icon={faX} style={{ color: 'red' }} />
              )}
            </td>
            <td>{period.isCustom === '1' ? 'Yes' : ''}</td>
          </tr>
        );
      });

    const TTARows = this.state.periods
      .filter((r) => r.status === '2')
      .map((period) => {
        return (
          <tr
            key={period.uid}
            className="cursor-pointer"
            onClick={() => {
              this.setState({ modalSwitch: 'editor', selectedPayPeriod: period });
            }}
          >
            <td>{period.uid}</td>
            <td>{this.state.companies.find((r) => r.uid === period.companyIdentifier).companyName}</td>
            <td>{period.csaName ?? 'All'}</td>
            <td>
              {moment(period.periodStart).format('MMM D, YYYY') +
                ' - ' +
                moment(period.periodEnd).format('MMM D, YYYY')}
            </td>
            <td>{usdFormatter.format(period.gross)}</td>
            <td>{period.isCustom === '1' ? 'Yes' : ''}</td>
          </tr>
        );
      });

    const historyRows = this.state.historicPeriods.map((period) => {
      return (
        <tr
          key={period.uid}
          className="cursor-pointer"
          onClick={() => {
            this.setState({ modalSwitch: 'viewer', selectedPayPeriod: period });
          }}
        >
          <td>{period.uid}</td>
          <td>{this.state.companies.find((r) => r.uid === period.companyIdentifier).companyName}</td>
          <td>{period.csaName ?? 'All'}</td>
          <td>
            {moment(period.periodStart).format('MMM D, YYYY') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}
          </td>
          <td>{period.gross ? usdFormatter.format(period.gross) : 'N/A'}</td>
          <td>{this.payrollStatusKey[period.status]}</td>
          <td>{period.isCustom === '1' ? 'Yes' : ''}</td>
        </tr>
      );
    });

    const sortPreviousPay = (a, b) => {
      if (moment(a.periodStart).isBefore(b.periodStart)) {
        return 1;
      } else if (moment(b.periodStart).isBefore(a.periodStart)) {
        return -1;
      } else {
        return b.uid - a.uid;
      }
    };

    const periodsEligibleToBePrevious =
      !this.state.selectedPayPeriod || this.state.periods.length < 2
        ? []
        : this.state.periods
            .filter(
              (r) =>
                r.status > 2 &&
                parseInt(r.uid) < parseInt(this.state.selectedPayPeriod.uid) &&
                r.companyIdentifier === this.state.selectedPayPeriod.companyIdentifier
            )
            .sort((a, b) => sortPreviousPay(a, b));

    const periodsToSend = [];

    for (let i = 0; i < periodsEligibleToBePrevious.length; i++) {
      periodsToSend.push(periodsEligibleToBePrevious[i]);
      if (periodsEligibleToBePrevious[i].isCustom == '0') {
        break;
      }
    }

    const content = this.state.isLoading ? (
      ''
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 12, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
          <Button
            onClick={() => {
              this.setState({ modalSwitch: 'createCustom' });
            }}
          >
            Create Custom Payroll
          </Button>
          {['superAdministrator', 'superDuperAdministrator'].includes(new Cookies().get('userData')?.permissions) && (
            <Button
              style={{ backgroundColor: 'gold', color: 'black' }}
              onClick={() => {
                this.setState({ modalSwitch: 'generatePayroll' });
              }}
            >
              Generate Payroll
            </Button>
          )}
          {['superAdministrator', 'superDuperAdministrator'].includes(new Cookies().get('userData')?.permissions) && (
            <Button
              style={{ backgroundColor: 'gold', color: 'black' }}
              onClick={() => {
                this.setState({ modalSwitch: 'generatePTOAccrual' });
              }}
            >
              Generate PTO Accrual
            </Button>
          )}
        </div>
        <Card>
          <Card.Body>
            <Card.Title>Ready for IC Review</Card.Title>
            <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
              <QuickTable
                size="sm"
                headers={['UID', 'Company', 'CSA', 'Period', 'BC Submission(s)', 'Custom?']}
                widths={[null, null, null, null, null, 1]}
                rows={ICRows}
                hover
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Ready for TTA Review</Card.Title>
            <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
              <QuickTable
                size="sm"
                headers={['UID', 'Company', 'CSA', 'Period', 'Gross', 'Custom?']}
                widths={[null, null, null, null, null, 1]}
                rows={TTARows}
                hover
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>History</Card.Title>
            <div>
              <LoadingWrapper isLoading={this.state.isLoadingHistoric}>
                <QuickTable
                  headers={['UID', 'Company', 'CSA', 'Period', 'Gross', 'Status', 'Custom?']}
                  widths={[null, null, null, null, null, null, 1]}
                  rows={historyRows}
                  hover
                />
              </LoadingWrapper>
              <div style={{ display: 'flex', gap: 6, alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  disabled={this.state.page == 0}
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <span>Page {this.state.page + 1}</span>
                <Button
                  disabled={this.state.historicPeriods.length == 0}
                  onClick={() => {
                    this.setState({ page: this.state.page + 1 });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );

    return (
      <div className="page-wrapper" style={{ padding: 12 }}>
        {this.state.isLoading ? <PageSpinner /> : content}
        <Modal show={this.state.modalSwitch === 'editor'} backdrop="static" fullscreen>
          <PayrollEditor
            selectedPayPeriod={this.state.selectedPayPeriod}
            periodsToSend={periodsToSend}
            hideModal={() => {
              this.setState({ modalSwitch: 'none' });
            }}
            loadData={this.loadData}
            companyName={
              this.state.selectedPayPeriod
                ? this.state.companies.find((c) => c.uid === this.state.selectedPayPeriod.companyIdentifier).companyName
                : ''
            }
            handleDeletingCustomPeriod={this.handleDeletingCustomPeriod}
          />
        </Modal>
        <Modal
          show={this.state.modalSwitch === 'viewer'}
          size="xl"
          centered
          onHide={() => {
            this.setState({ modalSwitch: 'none' });
          }}
          fullscreen
        >
          <PayrollPeriod
            selectedPayPeriod={this.state.selectedPayPeriod}
            companyName={
              this.state.selectedPayPeriod
                ? this.state.companies.find((c) => c.uid === this.state.selectedPayPeriod.companyIdentifier).companyName
                : ''
            }
            editPeriod={() => {
              this.setState({ modalSwitch: 'editor' });
            }}
          />
        </Modal>
        <Modal
          show={this.state.modalSwitch === 'generatePayroll'}
          centered
          onHide={() => {
            this.setState({ modalSwitch: undefined });
          }}
        >
          <GeneratePayrollModal />
        </Modal>
        <Modal
          show={this.state.modalSwitch === 'generatePTOAccrual'}
          centered
          onHide={() => {
            this.setState({ modalSwitch: undefined });
          }}
        >
          <GeneratePTOAccrualModal
            companies={this.state.companies}
            handleAddPayrollPeriod={this.handleAddPayrollPeriod}
            payrollPeriods={this.state.periods.filter((p) => p.status == 3)}
          />
        </Modal>
        <AlertModal
          show={this.state.modalSwitch === 'advancePendingPeriod'}
          onHide={() => {
            this.setState({ modalSwitch: 'none' });
          }}
          title={'Advance Payroll Period Early?'}
          message={
            'By confirming, the selected payroll period will become visible to the IC to review, even if the payroll period is still ongoing.'
          }
          buttonLabel={'Confirm'}
          callBack={this.handleAdvancePendingPayrollPeriod}
          centered
        />
        <Modal
          show={this.state.modalSwitch === 'createCustom'}
          onHide={() => {
            this.setState({ modalSwitch: 'none' });
          }}
          centered
        >
          <CreateCustomPayroll
            companies={this.state.companies}
            handleAddPayrollPeriod={this.handleAddPayrollPeriod}
            payrollPeriods={this.state.periods.filter((p) => p.isCustom == '0')}
          />
        </Modal>
      </div>
    );
  }
}
