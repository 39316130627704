/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from './serviceTools';

export async function getHistoricPayroll(page, count) {
  var data = {
    type: 'payroll',
    target: 'getHistoricPayroll',
    page: page,
    count: count,
    authToken: getToken(),
  };
  const response = await getResponse(data);
  return response;
}

export async function getActivePayroll() {
  var data = {
    type: 'payroll',
    target: 'getActivePayroll',
    authToken: getToken(),
  };
  const response = await getResponse(data);
  return response;
}

export async function getPayrollPeriod(uid) {
  var data = {
    type: 'payroll',
    target: 'getPayrollPeriod',
    authToken: getToken(),
    uid: uid,
  };
  const response = await getResponse(data);

  return response;
}

export async function reviewPayrollPeriod(period) {
  var data = {
    type: 'payroll',
    target: 'reviewPayrollPeriod',
    authToken: getToken(),
    period: period,
  };
  const response = await getResponse(data);
  return response;
}

export async function approvePayrollPeriod(payrollPeriod, pto, ptoAccrual, loans, tickets) {
  var data = {
    type: 'payroll',
    target: 'approvePayrollPeriod',
    authToken: getToken(),
    data: payrollPeriod,
    pto: pto,
    ptoAccrual: ptoAccrual,
    loans: loans,
    tickets: tickets,
  };

  const response = await getResponse(data);
  return response;
}

export async function advancePendingPayrollPeriod(uid) {
  var data = {
    type: 'payroll',
    target: 'advancePayrollPeriod',
    authToken: getToken(),
    uid: uid,
  };
  const response = await getResponse(data);
  return response;
}

// export async function getHRUserTimesheetEntries(companyUserIdentifier, startDate, endDate) {
//     var data = {
//         type: 'hr',
//         target: 'getTimesheet',
//         authToken: getToken(),
//         companyUserIdentifier: companyUserIdentifier,
//         startDate: startDate,
//         endDate: endDate
//     }

//     // const response = await getResponse(data);

//     // return response;

//     await timeout(500);

//     const timesheetData = fakeTimesheet.filter(r => companyUserIdentifier === r.companyUserIdentifier && moment(r.inTime).isBetween(moment(startDate), moment(endDate), 'days', '[]'));

//     return {
//         status: '200',
//         data: timesheetData,
//     }

// }

export async function createCustomPayroll(companyIdentifier, startDate, endDate) {
  var data = {
    type: 'payroll',
    target: 'createCustomPayroll',
    authToken: getToken(),
    companyIdentifier: companyIdentifier,
    startDate: startDate,
    endDate: endDate,
  };

  const response = await getResponse(data);
  return response;
}

export async function generatePTOAccrual(payrollIdentifier) {
  var data = {
    type: 'adminTools',
    target: 'backpayPtoAccrual',
    authToken: getToken(),
    payrollIdentifier,
  };

  const response = await getResponse(data);
  return response;
}

export async function savePayrollVersion(payrollVersion, gross) {
  var data = {
    type: 'payroll',
    target: 'savePayrollVersion',
    authToken: getToken(),
    payrollVersion: payrollVersion,
    gross: gross,
  };

  const response = await getResponse(data);
  return response;
}

export async function getPayrollVersion(uid, type, companyIdentifier, payrollIdentifier) {
  var data = {
    type: 'payroll',
    target: 'getPayrollVersion',
    authToken: getToken(),
    uid: uid,
    versionType: type,
    companyIdentifier: companyIdentifier,
    payrollIdentifier: payrollIdentifier,
  };

  return await getResponse(data);
}

export async function sendBackPayrollPeriod(uid, instructions) {
  var data = {
    type: 'payroll',
    target: 'sendBackPayrollPeriod',
    authToken: getToken(),
    uid: uid,
    instructions: instructions,
  };

  const response = await getResponse(data);
  return response;
}

export async function getPayrollDataForUsers(companyUserIdentifierArray, payrollIdentifier) {
  var data = {
    type: 'payroll',
    target: 'getPayrollDataForUsers',
    authToken: getToken(),
    uids: companyUserIdentifierArray,
    payrollIdentifier: payrollIdentifier,
  };
  const response = await getResponse(data);
  return response;
}

export async function deletePayroll(uid) {
  var data = {
    type: 'payroll',
    target: 'deletePayroll',
    authToken: getToken(),
    uid: uid,
  };

  const response = await getResponse(data);
  return response;
}

export async function generatePayroll(date) {
  var data = {
    type: 'payroll',
    target: 'generatePayroll',
    authToken: getToken(),
    date: date,
  };

  return await getResponse(data);
}

export async function getAllEmployeeLoans(companyUserIdentifier) {
  const data = {
    type: 'payroll',
    target: 'getAllLoans',
    authToken: getToken(),
    companyUserIdentifier: companyUserIdentifier,
  };
  const response = await getResponse(data);
  return response;
}

export async function getEmployeePayrollPto(companyUserIdentifier, payrollPeriod) {
  var data = {
    type: 'payroll',
    target: 'getEmployeePayrollPto',
    authToken: getToken(),
    companyUserIdentifier: companyUserIdentifier,
    payrollPeriod: payrollPeriod,
  };

  return await getResponse(data);
}
